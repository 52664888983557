import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, throwError } from 'rxjs';

interface ResetPasswordResponse {
  result: number;
}

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  constructor(private httpClient: HttpClient) {}

  async requestPasswordReset(
    emailAddress: string,
    reCaptchaToken: string,
  ): Promise<void> {
    const request = {
      emailAddress: emailAddress,
    };

    await lastValueFrom(
      this.httpClient.post<void>('passwords/request-reset', request, {
        headers: {
          'X-Recaptcha-Token': reCaptchaToken,
        },
      }),
    );
  }

  async resetPassword(
    token: string,
    newPassword: string,
    reCaptchaToken: string,
  ): Promise<void> {
    const request = {
      newPassword: newPassword,
      token: token,
    };

    await lastValueFrom(
      this.httpClient
        .post<ResetPasswordResponse>('passwords/reset', request, {
          headers: {
            'X-Recaptcha-Token': reCaptchaToken,
          },
        })
        .pipe(
          catchError((error: HttpErrorResponse) => throwError(() => error)),
        ),
    );
  }
}
