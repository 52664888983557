export class User {
  public get fullName(): string {
    return [this.givenName, this.middleName, this.familyName]
      .filter((n) => n)
      .join(' ');
  }

  constructor(
    public userId: string,
    public emailAddress: string,
    public givenName: string | null,
    public middleName: string | null,
    public familyName: string | null,
    public isEmailVerified: boolean,
    public profileImageIrl: string | null,
    public isMfaEnabled: boolean,
  ) {}
}
