import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RegisterComponent } from '../register/register.component';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from '../login/login.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

type ViewType = 'login' | 'register';

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss'],
  standalone: true,
  imports: [
    LoginComponent,
    MatButtonModule,
    RegisterComponent,
    MatProgressBarModule,
    MatDividerModule,
    MatTabsModule,
    MatIconModule,
  ],
})
export class LoginOrRegisterComponent implements OnInit {
  public currentView!: ViewType;
  public isLoading = false;
  public googleAuthorizeUrl: URL;
  constructor(
    @Inject('BASE_API_URL') apiUrl: string,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    const returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    this.googleAuthorizeUrl = new URL(`${apiUrl}/google/authorize`);
    if (returnUrl) {
      this.googleAuthorizeUrl.searchParams.append('returnUrl', returnUrl);
    }
  }

  ngOnInit(): void {
    this.setCurrentView('login');
  }

  public setCurrentView(newView: ViewType): void {
    this.currentView = newView;
    switch (newView) {
      case 'login':
        this.titleService.setTitle('Stratosheet - Log In');
        break;
      case 'register':
        this.titleService.setTitle('Stratosheet - Register');
    }
  }

  public loadingChanged(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
