export enum PasswordResetResult {
  Unknown = 0,
  Success = 1,
  InvalidResetToken = 2,
  AccountDisabled = 3,
  NewPasswordDoesNotMeetRequirements = 4,
  PasswordAlreadyUsed = 5,
  PasswordResetTokenExpired = 6,
  PasswordResetTokenAlreadyUsed = 7,
}
