import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

export type DialogSize = 'small' | 'medium' | 'large' | 'custom';

@Injectable({
  providedIn: 'root',
})
export class DialogOpenerService {
  constructor(private dialog: MatDialog) {}

  public open<TComponent, TData, TReturn>(
    component: ComponentType<TComponent>,
    dialogSize: DialogSize,
    isFullScreenOnSmall: boolean,
    config?: MatDialogConfig<TData>,
  ): MatDialogRef<TComponent, TReturn> {
    {
      const dialogConfig: MatDialogConfig<TData> = {
        ...config,
      };

      const isSmall = window.matchMedia('(max-width: 641px)').matches;

      if (isFullScreenOnSmall && isSmall) {
        dialogConfig.maxHeight = '100dvh';
        dialogConfig.maxWidth = '100dvw';
        dialogConfig.width = '100%';
        dialogConfig.height = '100%';
        dialogConfig.panelClass = 'full-screen-dialog';
      } else {
        switch (dialogSize) {
          case 'small':
            dialogConfig.width = '40rem';
            break;
          case 'medium':
            dialogConfig.width = '60rem';
            break;
          case 'large':
            dialogConfig.width = '80rem';
            break;
        }
      }

      return this.dialog.open<TComponent, TData, TReturn>(
        component,
        dialogConfig,
      );
    }
  }
}
