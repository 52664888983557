import { Inject, Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from 'src/environments/environment';

export const SEGMENT_ANALYTICS_CONFIGURATION =
  'SEGMENT_ANALYTICS_CONFIGURATION';

export interface SegmentAnalyticsConfiguration {
  debugLogs: boolean;
  isEnabled: boolean;
  writeKey: string;
  cdnDomain: string;
  apiDomain: string;
}

@Injectable({
  providedIn: 'root',
})
export class SegmentAnalyticsService {
  private _analytics: AnalyticsBrowser | undefined;

  constructor(
    @Inject(SEGMENT_ANALYTICS_CONFIGURATION)
    private segmentAnalyticsConfiguration: SegmentAnalyticsConfiguration,
  ) {
    if (segmentAnalyticsConfiguration.isEnabled)
      try {
        this._analytics = AnalyticsBrowser.load(
          {
            writeKey: environment.segment.writeKey,
            cdnURL: environment.segment.cdnDomain,
          },
          {
            integrations: {
              'Segment.io': {
                apiHost: `${environment.segment.apiDomain}/v1`,
              },
            },
          },
        );
      } catch {
        // don't do anything if analytics is unavailable
      }
  }

  public identify(userId: string | null | undefined) {
    if (this._analytics) {
      try {
        if (userId) {
          this._analytics.identify(userId);
        } else {
          this._analytics.identify();
        }
      } catch {
        // don't do anything if analytics is unavailable
      }
    } else if (this.segmentAnalyticsConfiguration.debugLogs) {
      console.log(`Segment Identify: ${userId}`);
    }
  }

  public track() {
    if (this._analytics) {
      try {
        // TODO
      } catch {
        // don't do anything if analytics is unavailable
      }
    } else if (this.segmentAnalyticsConfiguration.debugLogs) {
      // TODO
    }
  }

  public page() {
    if (this._analytics) {
      try {
        this._analytics.page();
      } catch {
        // don't do anything if analytics is unavailable
      }
    } else if (this.segmentAnalyticsConfiguration.debugLogs) {
      console.log('Segment Page');
    }
  }
}
