import { Injectable } from '@angular/core';
import { AppConfiguration } from '../models/app-configuration';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigurationService } from '../services/app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationStoreService {
  private readonly _appConfiguration: BehaviorSubject<AppConfiguration>;
  readonly appConfiguration$: Observable<AppConfiguration>;

  constructor(private appConfigurationService: AppConfigurationService) {
    this._appConfiguration = new BehaviorSubject<AppConfiguration>(
      new AppConfiguration(null, ''),
    );
    this.appConfiguration$ = this._appConfiguration.asObservable();
  }

  get appConfiguration(): AppConfiguration {
    return this._appConfiguration.getValue();
  }

  set appConfiguration(newConfiguration: AppConfiguration) {
    this._appConfiguration.next(newConfiguration);
  }

  public async initialize(): Promise<void> {
    this.appConfiguration = await this.appConfigurationService.get();
  }
}
