<div class="flex items-center justify-center text-center w-full h-full">
  <div class="w-[32rem] max-w-[98%] flex flex-col justify-center">
    <div
      class="w-full branding-background flex justify-center items-center py-2 rounded-t"
    >
      <img src="/assets/grackle.svg" class="logo" alt="Gracklesoft Logo" />
    </div>
    <form
      class="w-full grid grid-cols-1 bg-white px-4 pt-4"
      [formGroup]="formGroup"
      (ngSubmit)="requestPasswordReset()"
    >
      <div class="grid grid-cols-1 gap-6">
        <div class="grid grid-cols-1 gap-2">
          <mat-form-field class="col-span-1 w-full">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" autofocus />
            @if (formGroup.controls.email.errors?.["email"]) {
              <mat-error>Not a valid email address.</mat-error>
            }
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="col-span-1 w-full"
            [disabled]="isLoading"
          >
            Send Reset Password Link
          </button>
        </div>
        <div class="w-full col-span-1 flex justify-end">
          <a mat-button color="accent" routerLink="/" [disabled]="isLoading">
            I Remember My Password
          </a>
        </div>
      </div>
    </form>
    <div class="bg-white rounded-b">
      @if (isLoading) {
        <mat-progress-bar
          mode="indeterminate"
          color="primary"
          class="absolute bottom-0"
        ></mat-progress-bar>
      }
      @if (!isLoading) {
        <div class="reserved-space"></div>
      }
    </div>
  </div>
</div>
