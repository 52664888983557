export enum LoginResult {
  Unknown = 0,
  Success = 1,
  InvalidCredentials = 2,
  AccountDisabled = 3,
  PasswordExpired = 4,
  SecondFactorRequired = 5,
  AccountLockedOut = 6,
  InvalidSecondFactor = 7,
}
