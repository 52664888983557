import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService {
  constructor(private httpClient: HttpClient) {}

  public async verifyEmail(token: string): Promise<void> {
    const request = {
      hash: token,
    };
    await lastValueFrom(
      this.httpClient.post<never>('email-verification/verify', request),
    );
  }

  public async requestEmailVerificationEmail(): Promise<void> {
    await lastValueFrom(
      this.httpClient.post<never>('email-verification/request', {}),
    );
  }
}
