import {
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { AppComponent } from './app/app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { environment } from 'src/environments/environment';
import { BaseUrlInterceptor } from './app/interceptors/apiInterceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { CustomSvgIconRegistryService } from './app/services/custom-svg-icon-registry.service';
import {
  SEGMENT_ANALYTICS_CONFIGURATION,
  SegmentAnalyticsService,
} from './app/services/segment-analytics.service';
import {
  NEWRELIC_CONFIGURATION,
  NewrelicService,
} from './app/services/newrelic.service';
import { APP_URLS } from './app/models/app-urls';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // Angular
      AppRoutingModule,
      BrowserModule,
      ClipboardModule,
      FormsModule,
      ReactiveFormsModule,
      // Angular Material
      MatButtonModule,
      MatCheckboxModule,
      MatDialogModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatMenuModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      // Third Party
      RecaptchaV3Module,
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: SEGMENT_ANALYTICS_CONFIGURATION,
      useValue: {
        debugLogs: environment.segment.debugLogs,
        isEnabled: environment.segment.isEnabled,
        writeKey: environment.segment.writeKey,
        cdnDomain: environment.segment.cdnDomain,
        apiDomain: environment.segment.apiDomain,
      },
    },
    {
      provide: NEWRELIC_CONFIGURATION,
      useValue: {
        isEnabled: environment.newrelic.isEnabled,
        accountId: environment.newrelic.accountId,
        trustKey: environment.newrelic.trustKey,
        agentId: environment.newrelic.agentId,
        applicationId: environment.newrelic.applicationId,
        licenseKey: environment.newrelic.licenseKey,
        assetDomain: environment.newrelic.assetDomain,
        beaconDomain: environment.newrelic.beaconDomain,
      },
    },
    {
      provide: 'BASE_API_URL',
      useValue: environment.apiUrl,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptcha.siteKey },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        minHeight: '15rem',
        minWidth: '20rem',
        maxWidth: '99vw',
        autoFocus: 'first-tabbable',
      },
    },
    {
      provide: APP_URLS,
      useValue: {
        publicUrl: environment.publicUrl,
      },
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => inject(CustomSvgIconRegistryService).initialize(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [NewrelicService, SegmentAnalyticsService],
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
