<form [formGroup]="formGroup" (ngSubmit)="login()">
  <div class="grid gap-y-2">
    <mat-form-field class="w-full">
      <mat-label>Email Address</mat-label>
      <input
        matInput
        formControlName="email"
        autocomplete="username"
        [readonly]="isEnteringMfaCode"
        #emailInput
      />
      @if (formGroup.controls.email.errors?.["email"]) {
        <mat-error>Not a valid email address</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        autocomplete="current-password"
        [readonly]="isEnteringMfaCode"
      />
    </mat-form-field>
    <p><a routerLink="/forgot-password">I Forgot My Password</a></p>
    @if (isEnteringMfaCode) {
      <mat-form-field class="w-full">
        <mat-label>Multi-Factor Auth Code</mat-label>
        <input
          type="text"
          matInput
          inputmode="numeric"
          formControlName="multiFactorCode"
          autocomplete="one-time-code"
          #mfaInput
        />
        @if (
          formGroup.controls.multiFactorCode.errors?.["minlength"] ||
          formGroup.controls.multiFactorCode.errors?.["maxlength"]
        ) {
          <mat-error>The code should be exactly 8 characters</mat-error>
        } @else if (formGroup.controls.multiFactorCode.errors?.["required"]) {
          <mat-error>The code is required</mat-error>
        } @else if (
          formGroup.controls.multiFactorCode.errors?.["invalidCode"]
        ) {
          <mat-error>The provided code is not valid or has expired</mat-error>
        }
      </mat-form-field>
    }
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="w-full"
      [disabled]="isLoggingIn"
    >
      Log In
    </button>
  </div>
</form>
