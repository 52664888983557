import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordComplexityValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const hasLowercase = /[a-z]/.test(control.value);
      const hasUppercase = /[A-Z]/.test(control.value);
      const hasNumber = /[0-9]/.test(control.value);
      if (!hasLowercase || !hasUppercase || !hasNumber) {
        return {
          passwordComplexity: {
            hasLowercase: hasLowercase,
            hasUppercase: hasUppercase,
            hasNumber: hasNumber,
          },
        };
      }
    }
    return null;
  };
}
