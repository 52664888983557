<div class="w-full h-full bg-cover bg-center background-image">
  @if (isLoading) {
    <div class="flex items-center justify-center text-center w-full h-full">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
  } @else {
    <div class="w-full h-full public-layout">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  }
</div>
