<div class="flex items-center justify-center text-center w-full h-full">
  <div class="w-[32rem] max-w-[98%] flex flex-col justify-center">
    <div
      class="w-full branding-background flex justify-center items-center py-2 rounded-t"
    >
      <img src="/assets/grackle.svg" class="logo" alt="Gracklesoft Logo" />
    </div>
    <form
      class="w-full grid grid-cols-1 bg-white p-4 rounded-b"
      [formGroup]="formGroup"
      (ngSubmit)="resetPassword()"
    >
      <div class="grid grid-cols-1 gap-6">
        <mat-form-field class="col-span-1 w-full">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            autocomplete="new-password"
          />
          @if (formGroup.controls.password.errors?.["minlength"]) {
            <mat-error>Must be at least 8 characters</mat-error>
          }
          @if (formGroup.controls.password.errors?.["passwordComplexity"]) {
            <mat-error>
              Must contain a number, a lowercase letter, and an uppercase
              character.
            </mat-error>
          }
          @if (formGroup.controls.password.errors?.["passwordAlreadyUsed"]) {
            <mat-error>The password was previously used.</mat-error>
          }
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="col-span-1 w-full"
          [disabled]="isSaving"
        >
          Reset Password
        </button>
        <div class="w-full col-span-1 flex justify-end">
          <a mat-button color="accent" routerLink="/">Log In Instead</a>
        </div>
      </div>
    </form>
  </div>
</div>
