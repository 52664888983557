import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { User } from '../models/user';
import { AppConfiguration } from '../models/app-configuration';

interface GetAppConfigurationResponseUser {
  userId: string;
  emailAddress: string;
  givenName: string | null | undefined;
  middleName: string | null | undefined;
  familyName: string | null | undefined;
  isEmailVerified: boolean;
  profileImageUrl: string | null | undefined;
  isMfaEnabled: boolean;
}

interface GetAppConfigurationResponse {
  user: GetAppConfigurationResponseUser | null | undefined;
  stratosheetUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  constructor(private httpClient: HttpClient) {}

  public async get(): Promise<AppConfiguration> {
    return await lastValueFrom(
      this.httpClient
        .get<GetAppConfigurationResponse>('appConfiguration/', {})
        .pipe(
          map(
            (response) =>
              new AppConfiguration(
                response.user
                  ? new User(
                      response.user.userId,
                      response.user.emailAddress,
                      response.user.givenName ?? null,
                      response.user.middleName ?? null,
                      response.user.familyName ?? null,
                      response.user.isEmailVerified,
                      response.user.profileImageUrl ?? null,
                      response.user.isMfaEnabled,
                    )
                  : null,
                response.stratosheetUrl,
              ),
          ),
        ),
    );
  }
}
