import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { lastValueFrom } from 'rxjs';
import { PasswordResetService } from 'src/app/services/password-reset.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { AutofocusDirective } from '../../directives/autofocus.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutofocusDirective,
    MatButtonModule,
    RouterLink,
    MatProgressBarModule,
  ],
})
export class ForgotPasswordComponent implements AfterViewChecked, OnDestroy {
  public isLoading = false;
  public formGroup = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
  });
  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private passwordResetService: PasswordResetService,
    private snackBar: MatSnackBar,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnDestroy(): void {
    this.snackBarRef?.dismiss();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public async requestPasswordReset(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading = true;
    this.formGroup.disable();

    const emailAddress = this.formGroup.controls.email.value!;

    try {
      const reCaptchaToken = await lastValueFrom(
        this.recaptchaV3Service.execute('forgot_password'),
      );
      await this.passwordResetService.requestPasswordReset(
        emailAddress,
        reCaptchaToken,
      );
      this.snackBarRef = this.snackBar.open(
        'A password reset link has been sent to your email address.',
        'Dismiss',
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 429) {
        this.showError(
          'You have tried to register too many times. Please try again later.',
        );
      } else {
        this.showError('An error occurred while requesting a password reset.');
      }
    } finally {
      this.isLoading = false;
      this.formGroup.enable();
    }
  }

  private showError(message: string) {
    this.snackBarRef = this.snackBar.open(message, 'Dismiss');
  }
}
