import { Component, Inject } from '@angular/core';
import { APP_URLS, AppUrls } from 'src/app/models/app-urls';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  public privacyUrl: string;
  public termsUrl: string;

  constructor(@Inject(APP_URLS) appUrls: AppUrls) {
    this.privacyUrl = `${appUrls.publicUrl}/privacy`;
    this.termsUrl = `${appUrls.publicUrl}/terms`;
  }
}
