import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MultiFactorAuthenticationService } from 'src/app/services/multi-factor-authentication.service';
import { MultiFactorAuthenticationSetupInformation } from 'src/app/models/multi-factor-authentication';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { DialogOpenerService } from 'src/app/services/dialog-opener.service';
import { ErrorSnackBarService } from 'src/app/services/error-snack-bar.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { AppConfigurationStoreService } from 'src/app/stores/app-configuration-store.service';

export function showAddMultifactorAuthenticationDialog(
  dialog: DialogOpenerService,
) {
  return dialog.open<AddMultiFactorAuthenticationDialogComponent, never, never>(
    AddMultiFactorAuthenticationDialogComponent,
    'small',
    true,
    {
      autoFocus: 'dialog',

      disableClose: true,
    },
  );
}

@Component({
  selector: 'app-add-multi-factor-authentication-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
  ],
  templateUrl: './add-multi-factor-authentication-dialog.component.html',
  styleUrl: './add-multi-factor-authentication-dialog.component.scss',
})
export class AddMultiFactorAuthenticationDialogComponent
  implements OnInit, OnDestroy
{
  public isEnrolling = false;
  public isVerifying = false;
  public isLoading = true;
  public codeWasInvalid = false;
  public info!: MultiFactorAuthenticationSetupInformation;
  public hasVerified = false;

  public verifyFormGroup = this.formBuilder.group({
    appCode: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8),
        this.codeValidator(),
      ],
    ],
  });

  public enrollFormGroup = this.formBuilder.group({
    acknowledgement: [false, Validators.requiredTrue],
  });

  private snackbarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(
    private dialogRef: MatDialogRef<
      AddMultiFactorAuthenticationDialogComponent,
      never
    >,
    private formBuilder: FormBuilder,
    private multiFactorAuthenticationService: MultiFactorAuthenticationService,
    private snackBar: ErrorSnackBarService,
    private clipboard: Clipboard,
    private appConfigurationStore: AppConfigurationStoreService,
  ) {}

  ngOnDestroy(): void {
    this.snackbarRef?.dismiss();
  }

  ngOnInit(): void {
    this.multiFactorAuthenticationService
      .getMultiFactorSetUpInformation()
      .then((info) => {
        this.info = info;
        this.isLoading = false;
      });
    this.verifyFormGroup.controls.appCode.valueChanges.subscribe(() => {
      this.codeWasInvalid = false;
    });
  }

  private codeValidator(): ValidatorFn {
    return () => {
      if (this.codeWasInvalid) {
        return { invalidCode: true };
      }
      return null;
    };
  }

  public async verify(): Promise<void> {
    this.codeWasInvalid = false;
    if (!this.verifyFormGroup.valid) {
      return;
    }

    this.isVerifying = true;
    try {
      this.hasVerified =
        await this.multiFactorAuthenticationService.verifyMultiFactorAuthentication(
          this.verifyFormGroup.controls.appCode.value!,
          this.info.secret,
        );
      this.codeWasInvalid = !this.hasVerified;
      this.verifyFormGroup.controls.appCode.updateValueAndValidity();
    } catch (e) {
      this.snackBar.open(
        e,
        'There was a problem verifying the code. Try again.',
        'OK',
      );
    } finally {
      this.isVerifying = false;
    }
  }

  public copyRecoveryCode() {
    this.clipboard.copy(this.info.recoveryCode);
  }

  public async enroll(): Promise<void> {
    if (!this.enrollFormGroup.valid) {
      return;
    }

    this.isEnrolling = true;
    try {
      await this.multiFactorAuthenticationService.enroll(
        this.info.secret,
        this.info.recoveryCode,
      );
      this.appConfigurationStore.appConfiguration.user!.isMfaEnabled = true;
      this.dialogRef.close();
    } catch (e) {
      this.snackBar.open(
        e,
        'There was a problem enrolling in multi-factor authentication. Try again.',
        'OK',
      );
    } finally {
      this.isEnrolling = false;
    }
  }
}
