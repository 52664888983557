import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const signInWithGoogleIconSvg = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="display:block">
  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
  <path fill="none" d="M0 0h48v48H0z"/>
</svg>
`;

const signInWithAppleSvg = `
<svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd">
    <path fill="#FFF" d="M6 6h44v44H6z"/>
    <path d="M28.2227 20.3846c.832 0 1.875-.5798 2.496-1.3528.5626-.7006.9727-1.679.9727-2.6574 0-.1328-.0117-.2657-.0352-.3744-.9257.0362-2.039.6402-2.707 1.4495-.5273.616-1.0078 1.5823-1.0078 2.5728 0 .1449.0234.2898.0352.3382.0586.012.1523.0241.246.0241ZM25.293 35c1.1367 0 1.6406-.7851 3.0586-.7851 1.4414 0 1.7578.761 3.0234.761 1.2422 0 2.0742-1.1838 2.8594-2.3434.8789-1.3286 1.2422-2.6331 1.2656-2.6935-.082-.0242-2.461-1.0267-2.461-3.8411 0-2.44 1.875-3.5391 1.9805-3.6236-1.2422-1.836-3.1289-1.8843-3.6445-1.8843-1.3945 0-2.5313.8696-3.246.8696-.7735 0-1.793-.8213-3-.8213-2.297 0-4.629 1.9567-4.629 5.6529 0 2.295.8672 4.7228 1.9336 6.293C23.3476 33.913 24.1446 35 25.293 35Z" fill="#000" fill-rule="nonzero"/>
  </g>
</svg>
`;

@Injectable({
  providedIn: 'root',
})
export class CustomSvgIconRegistryService {
  public readonly signInWithGoogleIconName = 'sign-in-with-google';
  public readonly signInWithAppleIconName = 'sign-in-with-apple';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public initialize() {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this.addIcon(this.signInWithGoogleIconName, signInWithGoogleIconSvg);
    this.addIcon(this.signInWithAppleIconName, signInWithAppleSvg);
  }

  private addIcon(svgIconName: string, svgString: string) {
    this.matIconRegistry.addSvgIconLiteral(
      svgIconName,
      this.domSanitizer.bypassSecurityTrustHtml(svgString),
    );
  }
}
