import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, throwError } from 'rxjs';

interface RegisterSuccessResponse {
  redirectUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(private httpClient: HttpClient) {}

  async register(
    emailAddress: string,
    password: string,
    returnUrl: string | undefined | null,
    reCaptchaToken: string,
  ): Promise<string> {
    const request = {
      emailAddress: emailAddress,
      password: password,
      returnUrl: returnUrl,
    };
    const response = await lastValueFrom(
      this.httpClient
        .post<RegisterSuccessResponse>('register', request, {
          headers: {
            'X-Recaptcha-Token': reCaptchaToken,
          },
        })
        .pipe(
          catchError((error: HttpErrorResponse) => throwError(() => error)),
        ),
    );

    return response.redirectUrl;
  }
}
