<form [formGroup]="formGroup" (ngSubmit)="register()">
  <div class="grid gap-y-2">
    <mat-form-field class="w-full">
      <mat-label>Email Address</mat-label>
      <input
        matInput
        formControlName="email"
        autocomplete="email"
        #emailInput
      />
      @if (formGroup.controls.password.errors?.["required"]) {
        <mat-error>Email address is required</mat-error>
      } @else if (formGroup.controls.email.errors?.["email"]) {
        <mat-error>Not a valid email address</mat-error>
      }
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        autocomplete="current-password"
      />
      @if (formGroup.controls.password.errors?.["required"]) {
        <mat-error>Password is required</mat-error>
      } @else if (formGroup.controls.password.errors?.["minlength"]) {
        <mat-error>Must be at least 8 characters</mat-error>
      } @else if (formGroup.controls.password.errors?.["passwordComplexity"]) {
        <mat-error>
          Must contain a number, a lowercase letter, and an uppercase character.
        </mat-error>
      }
    </mat-form-field>
    <div class="grid gap-y-2">
      <p>
        By registering, you accept the
        <a [href]="termsUrl" target="_blank">terms of service</a>
        and
        <a [href]="privacyUrl" target="_blank">privacy policy</a>
        .
      </p>
    </div>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="w-full"
      [disabled]="isRegistering"
    >
      Register
    </button>
  </div>
</form>
