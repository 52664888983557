import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

interface LoginSuccessResponse {
  redirectUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  async logIn(
    emailAddress: string,
    password: string,
    mfaCode: string | undefined | null,
    returnUrl: string | undefined | null,
    reCaptchaToken: string,
  ): Promise<string> {
    const request = {
      emailAddress: emailAddress,
      password: password,
      mfaCode: mfaCode,
      returnUrl: returnUrl,
    };
    const response = await lastValueFrom(
      this.httpClient.post<LoginSuccessResponse>('login', request, {
        headers: {
          'X-Recaptcha-Token': reCaptchaToken,
        },
      }),
    );

    return response.redirectUrl;
  }

  async logOut(): Promise<void> {
    await lastValueFrom(this.httpClient.post('logout', null, {}));
  }
}
