import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ErrorSnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  public open(
    e: Error | unknown,
    message: string,
    action: string | undefined,
    config?: MatSnackBarConfig<unknown> | undefined,
  ) {
    let messageToShow = message;
    if (e instanceof HttpErrorResponse && e.status === 422) {
      messageToShow = e.error;
    }
    return this.snackBar.open(messageToShow, action, config);
  }
}
