import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string | undefined | null,
    maxLength: number,
    truncationMarker = '...',
  ) {
    if (!value) {
      return value;
    }
    if (value.length <= maxLength) {
      return value;
    }

    return (
      value.substring(0, maxLength - truncationMarker.length) + truncationMarker
    );
  }
}
