/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'button[mat-large-icon-button]',
  standalone: true,
})
export class MatLargeIconButtonDirective {
  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('mat-large-icon-button');
  }
}

@Directive({
  selector: 'button[mat-medium-icon-button]',
  standalone: true,
})
export class MatMediumIconButtonDirective {
  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('mat-medium-icon-button');
  }
}

@Directive({
  selector: 'button[mat-small-icon-button]',
  standalone: true,
})
export class MatSmallIconButtonDirective {
  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('mat-small-icon-button');
  }
}

@Directive({
  selector: 'button[mat-tiny-icon-button]',
  standalone: true,
})
export class MatTinyIconButtonDirective {
  constructor(elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('mat-tiny-icon-button');
  }
}
