import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { lastValueFrom } from 'rxjs';
import { LoginResult } from 'src/app/models/loginResult';
import { LoginService } from 'src/app/services/login.service';
import { MatButtonModule } from '@angular/material/button';

import { AutofocusDirective } from '../../directives/autofocus.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutofocusDirective,
    RouterLink,
    MatButtonModule,
  ],
})
export class LoginComponent
  implements AfterViewInit, AfterViewChecked, OnDestroy
{
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('mfaInput') mfaInput: ElementRef<HTMLInputElement> | undefined;

  @Output() public loadingChanged = new EventEmitter<boolean>();
  public formGroup = this.formBuilder.group(
    {
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      multiFactorCode: [
        '',
        [
          Validators.minLength(8),
          Validators.maxLength(8),
          this.codeValidator(),
        ],
      ],
    },
    { updateOn: 'submit' },
  );
  public isLoggingIn = false;
  public isEnteringMfaCode = false;

  private codeWasInvalid = false;
  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnDestroy(): void {
    this.snackBarRef?.dismiss();
  }

  ngAfterViewInit(): void {
    this.emailInput?.nativeElement.focus();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private prepareForMultiFactorAuthentication() {
    this.formGroup.controls.multiFactorCode.addValidators(Validators.required);
    this.formGroup.controls.multiFactorCode.markAsPristine();
    this.isEnteringMfaCode = true;
    this.changeDetectorRef.detectChanges();
    this.mfaInput?.nativeElement.focus();
  }

  private codeValidator(): ValidatorFn {
    return () => {
      if (this.codeWasInvalid) {
        return { invalidCode: true };
      }
      return null;
    };
  }

  public async login(): Promise<void> {
    this.codeWasInvalid = false;
    this.formGroup.controls.multiFactorCode.updateValueAndValidity();
    if (!this.formGroup.valid) {
      return;
    }

    const returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    this.formGroup.disable();
    this.codeWasInvalid = false;
    this.isLoggingIn = true;
    this.loadingChanged.emit(true);
    try {
      const reCaptchaToken = await lastValueFrom(
        this.recaptchaV3Service.execute('login'),
      );
      const redirectUrl = await this.loginService.logIn(
        this.formGroup.controls.email.value!,
        this.formGroup.controls.password.value!,
        this.formGroup.controls.multiFactorCode.value,
        returnUrl,
        reCaptchaToken,
      );

      window.location.assign(redirectUrl);
    } catch (e) {
      this.isLoggingIn = false;
      this.loadingChanged.emit(false);
      this.formGroup.enable();
      if (e instanceof HttpErrorResponse && e.status === 422) {
        switch (e.error.loginResult) {
          case LoginResult.AccountDisabled:
            this.showError(
              'Your account is disabled. Contact your account administrator.',
            );
            break;
          case LoginResult.InvalidCredentials:
            this.showError('Your email or password were incorrect.');
            break;
          case LoginResult.PasswordExpired:
            this.showError('Your password has expired.');
            break;
          case LoginResult.AccountLockedOut:
            this.showError('Your account is locked. Try again later.');
            break;
          case LoginResult.SecondFactorRequired:
            this.prepareForMultiFactorAuthentication();
            break;
          case LoginResult.InvalidSecondFactor:
            this.codeWasInvalid = true;
            this.showError('The two-factor code you entered was incorrect.');
            break;
          case LoginResult.Unknown:
            this.showError('An error occurred logging in. Try again.');
            break;
        }
      } else if (e instanceof HttpErrorResponse && e.status === 429) {
        this.showError(
          'You have tried to log in too many times. Please try again later.',
        );
      } else {
        this.showError('An error occurred logging in. Try again.');
      }
    }
  }

  private showError(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
