export const environment = {
  apiUrl: 'https://account.dev.stratosheet.com/api',
  reCaptcha: {
    siteKey: '6Lc7rUYlAAAAAF43sw7lCsEqhgsukx9nVX4ZwWx8',
  },
  name: 'development',
  segment: {
    debugLogs: false,
    isEnabled: false,
    writeKey: '',
    cdnDomain: 'https://t.stratosheet.com/segment-cdn',
    apiDomain: 't.stratosheet.com/segment-api/', // Intentionally no protocol.
  },
  newrelic: {
    isEnabled: true,
    accountId: '4112580',
    trustKey: '4112580',
    agentId: '594481159',
    licenseKey: 'NRJS-e787bc18fe61556a8e4',
    applicationId: '594481159',
    assetDomain: 't.stratosheet.com/nrjs',
    beaconDomain: 't.stratosheet.com/nr',
  },
  publicUrl: 'https://dev.stratosheet.com',
};
