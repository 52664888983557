import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { SegmentAnalyticsService } from 'src/app/services/segment-analytics.service';
import { FooterComponent } from '../footer/footer.component';
import { AppConfigurationStoreService } from 'src/app/stores/app-configuration-store.service';
@Component({
  selector: 'app-public-index',
  standalone: true,
  templateUrl: './public-index.component.html',
  styleUrl: './public-index.component.scss',
  imports: [RouterModule, MatProgressSpinnerModule, FooterComponent],
})
export class PublicIndexComponent implements OnInit, OnDestroy {
  public isLoading = true;

  private routerEventSubscription: Subscription | undefined;

  constructor(
    private appConfigurationStore: AppConfigurationStoreService,
    private router: Router,
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.appConfigurationStore.initialize().then(() => {
      this.isLoading = false;
      this.segmentAnalyticsService.identify(
        this.appConfigurationStore.appConfiguration.user?.userId,
      );
      if (this.appConfigurationStore.appConfiguration.user) {
        this.router.navigate(['/account']);
        return;
      }
    });

    this.routerEventSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.segmentAnalyticsService.page();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEventSubscription?.unsubscribe();
  }
}
