<mat-toolbar color="primary" role="navigation">
  <a routerLink="/workspaces" class="mr-5">
    <img src="/assets/grackle.svg" class="logo" alt="Gracklesoft Logo" />
  </a>

  <a mat-button [href]="stratosheetUrl">Stratosheet</a>

  <span class="flex-auto"></span>
  <span class="hidden sm:inline">
    <button mat-button [matMenuTriggerFor]="userMenu">
      <span>{{ user.emailAddress | truncate: 50 }}</span>
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
  </span>
  <span class="inline sm:hidden">
    <button
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
      matTooltip="User Menu"
      aria-label="User Menu"
    >
      <mat-icon>account_circle</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-menu #userMenu="matMenu" xPosition="before">
  <span mat-menu-item disabled class="inline lg:!hidden">
    {{ user.emailAddress }}
  </span>
  <!-- <button mat-menu-item>
    <mat-icon>settings</mat-icon>
    Manage Subscription
  </button> -->
  <button mat-menu-item (click)="logOut()">
    <mat-icon>logout</mat-icon>
    Log Out
  </button>
</mat-menu>
