import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { MultiFactorAuthenticationSetupInformation } from '../models/multi-factor-authentication';

interface GetMultiFactorAuthenticationSetUpInformationResponse {
  secret: string;
  recoveryCode: string;
  base64Image: string;
}

interface VerifyMultiFactorAuthenticationResponse {
  isValid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MultiFactorAuthenticationService {
  constructor(private httpClient: HttpClient) {}

  public async getMultiFactorSetUpInformation(): Promise<MultiFactorAuthenticationSetupInformation> {
    return await lastValueFrom(
      this.httpClient
        .get<GetMultiFactorAuthenticationSetUpInformationResponse>(
          'multi-factor-auth/enrollment-info',
        )
        .pipe(
          map(
            (response: GetMultiFactorAuthenticationSetUpInformationResponse) =>
              new MultiFactorAuthenticationSetupInformation(
                response.secret,
                response.recoveryCode,
                `data:image/png;base64, ${response.base64Image}`,
              ),
          ),
        ),
    );
  }

  public async verifyMultiFactorAuthentication(
    code: string,
    secret: string,
  ): Promise<boolean> {
    return await lastValueFrom(
      this.httpClient
        .post<VerifyMultiFactorAuthenticationResponse>(
          'multi-factor-auth/verify-enrollment-code',
          { code: code, secret: secret },
        )
        .pipe(map((response) => response.isValid)),
    );
  }

  public async enroll(secret: string, recoveryCode: string): Promise<void> {
    await lastValueFrom(
      this.httpClient.post('multi-factor-auth/enroll', {
        secret: secret,
        recoveryCode: recoveryCode,
      }),
    );
  }
}
